import {Injectable} from "@angular/core";
import {OnDestroy} from "@angular/core";
import {Observable} from "rxjs";
import {BehaviorSubject} from "rxjs";
import {ITargetModel} from "../models/exam.model";
import {BackendService} from "../services/backend.service";

interface ExamTypeTargets {
  [examType: number]: BehaviorSubject<ITargetModel[]>;
}

@Injectable()
export class PossibleExamTargetsService implements OnDestroy {
  constructor(private backendService: BackendService) {
  }

  public getPossibleExamTargetsForExamType(examTypeId: number): BehaviorSubject<ITargetModel[]> {
    let targets = this.possibleExamTargets[examTypeId];
    if (targets == null) {
      targets = new BehaviorSubject<ITargetModel[]>([]);
      this.possibleExamTargets[examTypeId] = targets;
      this.updatePossibleExamTargets(examTypeId);
    }

    return targets;
  }

  public ngOnDestroy(): void {
    for (const examTypeId in this.possibleExamTargets) {
      if (!this.possibleExamTargets.hasOwnProperty(examTypeId)) {
        continue;
      }
      this.possibleExamTargets[examTypeId].complete();
      delete this.possibleExamTargets[examTypeId];
    }
  }


  private updatePossibleExamTargets(examTypeId: number): void {
    this.backendService.get<ITargetModel[]>("/api/examtype/" + examTypeId + "/targets")
      .subscribe((targets: ITargetModel[]) => this.possibleExamTargets[examTypeId].next(targets));
  }

  private possibleExamTargets: ExamTypeTargets = {};

}

