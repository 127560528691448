import {ExamId} from "../../models/exam-id.model";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {ExamService} from "../../services/exam.service";
import {Injectable} from "@angular/core";

@Injectable()
export class ExamsIdResolver implements Resolve<ExamId[]> {
  constructor(private examService: ExamService) {
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ExamId[]> {
    return this.examService.getPossibleExamIds();
  }
}
